import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/news',
    name: 'News',
    component: () => import(/* webpackChunkName: "about" */ '../views/News.vue')
  },
  {
    path: '/newsdetail',
    name: 'Newsdetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/Newsdetail.vue')
  },
  {
    path: '/framer',
    name: 'Framer',
    component: () => import(/* webpackChunkName: "about" */ '../views/Framer.vue')
  },
  {
    path: '/merchant',
    name: 'Merchant',
    component: () => import(/* webpackChunkName: "about" */ '../views/Merchant.vue')
  },
  {
    path: '/solution',
    name: 'Solution',
    component: () => import(/* webpackChunkName: "about" */ '../views/Solution.vue')
  },
  {
    path: '/solucloud',
    name: 'Solucloud',
    component: () => import(/* webpackChunkName: "about" */ '../views/Solucloud.vue')
  },
  {
    path: '/soluvenue',
    name: 'Soluvenue',
    component: () => import(/* webpackChunkName: "about" */ '../views/Soluvenue.vue')
  },
  {
    path: '/studyabroad',
    name: 'Studyabroad',
    component: () => import(/* webpackChunkName: "about" */ '../views/Studyabroad.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    // to, from, savedPosition
    return { x: 0, y: 0 }
  }
})

export default router
