export const newslist = [
  {
    img: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/news/new21.png',
    type: '浙江体育报',
    time: '2023-08-02',
    title: '省教育厅公布！全省最新排名榜，杭州这些高中进入前十！',
    desc: '近日，浙江省教育厅公布2022学年高校新生（浙江籍高中毕业生）体质健康情况报告。在本次公布的数据中，杭州市平均分、优良率排名全省第一，并对生源地的各区县（市）和各高中进行了排名，一起来看↓↓',
    id: '71',
    newsType: '1',
    detail: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/news/detail21.png',
    linkUrl: 'https://mp.weixin.qq.com/s/tTbaKfxLRuIMcHE3yKb-aA'
  },
  {
    img: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/news/new20.png',
    type: '浙江体育报',
    time: '2023-03-07',
    title: '让“体育作业”变成“生活方式',
    desc: '据了解，在杭州市体育家庭作业课题组指导下，“130”趣味运动每周的“锻炼菜单”中共有8类套餐，学生可以自由选择感兴趣的套餐开展体育锻炼，每类套餐中包含了准备活动、技能练习、体能练习、特色项目、放松活动五大模块，学生在完成每周体育锻炼的过程中，还能潜移默化地学会如何科学合理运动。',
    id: '70',
    newsType: '1',
    detail: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/news/detail20.png',
    linkUrl: 'http://www.zjjyb.cn/html/2023-03/07/content_40937.htm'
  },
  {
    img: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/news/new91.jpeg',
    type: '国家体育总局',
    time: '2021-04-08',
    title: '赛事数字化3.0时代来了 浙江省青少年电子制作锦标赛专用平台启用',
    desc: '扫码进平台，赛事在线看，信息全知道，电子奖状自动生成……日前，浙江省青少年电子制作锦标赛专用平台正式启用，这是新冠肺炎疫情以来，省级线下赛首次采用该平台，标志着浙江赛事数字化3.0时代的开启。',
    id: '91',
    newsType: '1',
    detail: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/news/detail91.png',
    linkUrl: 'http://www.sport.gov.cn/n316/n343/n1191/c985320/content.html'
  },
  {
    img: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/news/new89.jpg',
    type: '体坛报',
    time: '2021-04-06',
    title: '一人一码 现场直播 成绩及时可查 数字化赋能为这场赛事“减负”',
    desc: '4月3日，为期5天的2021年浙江省青少年电子制作锦标赛在嘉兴开幕。本次比赛共吸引来自全省9个地市94支参赛队伍，共计2890人参加',
    id: '89',
    newsType: '1',
    detail: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/news/detail90.png',
    linkUrl: 'http://mobile.ttb0571.com/content/2021-04/06/058585.html'
  },
  {
    img: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/news/new90.png',
    type: '读嘉•城事',
    time: '2021-04-03',
    title: '浙江赛事数字化3.0时代开启！今天嘉兴举办的这个省赛意义非凡',
    desc: '4月3日，为期五天的2021年浙江省青少年电子制作锦标赛在嘉兴开幕。扫码进平台，赛事在线看，信息全知道，电子奖状自动生成……',
    id: '90',
    newsType: '1',
    detail: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/news/detail89.png',
    linkUrl: 'https://appexternal.cnjxol.com/webDetails/news?id=924162&tenantId=1'
  },
  /**
   * 2021-04-14
   */
  {
    img: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/news/new92.jpeg',
    type: '抚州市全媒体中心',
    time: '2021-03-01',
    title: '两会专访 | 市政协委员熊伟：抓住机遇 推进抚州数字化改革',
    desc: '“数字化改革是在‘最多跑一次’改革和政府数字化转型基础上的迭代深化，是新发展阶段全面深化改革的总抓手。” 2月28日，抚州市政协委员、市政府驻浙江招才引智工作站副站长、趣探路（杭州）网络技术有限公司董事长熊伟在接受全媒体中心记者采访时说道。在这个“万物互联”的数字时代，数字技术是未来竞争的利器，人工智能将是未来数字经济发展的重要工具之一。',
    id: '92',
    newsType: '1',
    detail: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/news/detail92.png',
    linkUrl: 'http://fuzhou.yun.jxxw.com.cn/p/122971494.html?from=singlemessage&isappinstalled=0'
  },
  {
    img: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/news/new95.png',
    type: '趣探路',
    time: '2021-01-28',
    title: '趣探路携手《留学生》杂志开展内容合作，共创优质留学内容',
    desc: '趣探路教育留学社区与《留学生》杂志自2020年12月下刊起，在《留学生》杂志的“趣留学”、“万象”等栏目进行内容合作。',
    id: '95',
    newsType: '2',
    detail: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/news/detail95.png',
    linkUrl: 'https://mp.weixin.qq.com/s?__biz=MjM5ODUyOTE2NQ==&mid=2247515789&idx=2&sn=bf7c2fada4a9bef9c2c078a122736373&chksm=a6cbaf6f91bc2679a3d335a7b637e9fbdd51e25045c5fddf869e3309b51c06b2dc435e4d9bf3&mpshare=1&scene=1&srcid=0122AazNG3VSqenFhwDK3uR4&sharer_sharetime=1612347198878&sharer_shareid=3c8d356f96164902c45b8a50ef4a4d4b&key=4e05d5b2ef4a48737fd7a91a208a6bd8d1ecca14470a4daf9a4d359ac1af494ae46d2490d2d48ed0d242876ef8c3363818ac84f0edb4e128e2ee2bf2c09c639fab97cbb79f40bd01c1c791c544dd6ce83af12267219a24419545361e993e8b9969f43c28096b0f87135b763c928c1f9a44785197de956b6746be0dedf733bb73&ascene=0&uin=MTUwMTY0MzYwMQ%3D%3D&devicetype=iMac+MacBookPro12%2C1+OSX+OSX+10.15.7+build(19H2)&version=12010110&nettype=WIFI&lang=zh_CN&fontScale=100&exportkey=AcA07hMf2FzHUZgsclqnRv8%3D&pass_ticket=K3p6%2BAjPIijUURZSBl5%2BYkjPvV%2FdctinKKNvwdPfQBJhYhEczrGnp4RnvfpjWB6Y&wx_header=0'
  },
  {
    img: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/news/new98.png',
    type: '杭州市西湖区青少年宫',
    time: '2021-01-25 19:24:46',
    title: '西湖棒伢儿云平台首次竞赛活动获高赞',
    desc: '自从西湖棒伢儿校外教育云平台上线后，西湖区学生的“幸福感”又得到了进一步提升，在一个平台上就能实现获取竞赛通知公示、参加竞赛活动、获取电子奖状等，实现了参加竞赛 “一次不用跑”的幸福体验。',
    id: '98',
    newsType: '1',
    detail: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/news/detail98.png',
    linkUrl: 'https://mp.weixin.qq.com/s?__biz=MzA4MjM3NjgxNQ==&mid=2650011185&idx=1&sn=60f97490796f0fb1f9a5944f80c21cbb&chksm=8781f731b0f67e27023a887a1e5940f0456be4bb8be28d7468fbdf2e3f7afe33ef6e2d902f4e&mpshare=1&scene=1&srcid=0125cep8onSO7dEvrfCJBvWR&sharer_sharetime=1611573289816&sharer_shareid=d1a6c9f25e13e1a92468dd33655ed034&key=eda6ea85bb7f4236c632dc6c6958fece754e0ce8c0fb7e430e2f3ae783acdaa00ce7bf03c4c20b0b1af76a151b0e214033ca156a9d9b2128eeedf57405fc46f1ecbf90168faffc57cd8ac957eba885d85db26e524d02c0a54f820d6d5bebbd268c4e8e1afcd7b0a8d532446d1f7a7b515aef155f50ea5ee3eca2275f26eb328a&ascene=0&uin=MTUwMTY0MzYwMQ%3D%3D&devicetype=iMac+MacBookPro12%2C1+OSX+OSX+10.15.7+build(19H2)&version=12010110&nettype=WIFI&lang=zh_CN&fontScale=100&exportkey=AfQmrh5B8Lu6x%2BltCNlnirs%3D&pass_ticket=s5X%2Fg1zRLZPzujAF3FzXMCyDcZDtQEXDvsy18HeMCQOcHif7n2uyGvEy1N3mOOno&wx_header=0'
  },
  {
    img: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/news/new97.png',
    type: '趣探路',
    time: '2021-01-22 21:47:31',
    title: '中新社浙江分社携手杭师大阿里巴巴商学院打造“数字经济大讲堂”',
    desc: '中新网浙江新闻1月22日电（郭其钰 苏礼昊）创新是第一动力，人才是第一资源。在数字经济迅猛发展的当下，培育创新型数字经济人才成为在危机中育先机、于变局中开新局的核心',
    id: '97',
    newsType: '2',
    detail: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/news/detail97.png',
    linkUrl: 'https://m.chinanews.com/wap/detail/chs/zw/6081909hafytswdf.shtml'
  },
  {
    img: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/news/new96.png',
    type: '美丽西湖 ',
    time: '2020-12-06',
    title: '家长注意！“西湖棒伢儿”平台上线，你家孩子参加区比赛不用校内选拔啦',
    desc: '学生成长需要舞台，可每次比赛，总会因为人数限制，让一些孩子登不上舞台。不过，从现在起，西湖区的同学们将拥有活动同等参赛的机会！',
    id: '96',
    newsType: '1',
    detail: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/news/detail96.png',
    linkUrl: 'https://mp.weixin.qq.com/s?__biz=MjM5NDExMzgwNw==&mid=2651594410&idx=2&sn=76c1c722c9fd72d866a7ee9d19c0d2e6&chksm=bd740eca8a0387dce2e84c27a230c7dcdb7f03b831fc5714b600d6ba97a1829514f32aebdd5e&mpshare=1&scene=1&srcid=1206CBCghtgInwkfXMQn70Pn&sharer_sharetime=1611748330013&sharer_shareid=d1a6c9f25e13e1a92468dd33655ed034&key=abc6ea0ac2aad4175d42f3ca7a0393da5fcac8bc32f1b1daf261934d75c5d13a47abb64992ac6446ca6fac00174e7b1b09ffbc37217f703921dbffd788cc025e74f0832606dbbc7c48fdca5359351104aa412bd9375c9a3556a9ef626e0ba241670042312d4532a14a9df6728bebdeed7af112c6c1a41c4192fda714bcf537c4&ascene=0&uin=MTUwMTY0MzYwMQ%3D%3D&devicetype=iMac+MacBookPro12%2C1+OSX+OSX+10.15.7+build(19H2)&version=12010110&nettype=WIFI&lang=zh_CN&fontScale=100&exportkey=AYPPpxyMnmUibDJDuxByX%2FI%3D&pass_ticket=s5X%2Fg1zRLZPzujAF3FzXMCyDcZDtQEXDvsy18HeMCQOcHif7n2uyGvEy1N3mOOno&wx_header=0'
  },
  {
    img: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/news/new0.png',
    type: '中新网 ',
    time: '2020-11-16',
    title: '趣探路CEO熊伟：为教育行业提供专业数字化服务',
    desc: '中新网浙江新闻11月16日电（记者 郭其钰）“目前还活着，并且活得挺好。”今年受疫情影响各种海外游学、留学断崖式下滑的情况下，趣探路（杭州）网络技术有限公司创始人、CEO熊伟接受中新网专访时，如此形容疫情大考下的趣探路平台',
    id: '0',
    newsType: '1',
    detail: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/news/detail0.png',
    linkUrl: 'https://m.chinanews.com/wap/detail/chs/zw/3794607hacwmzudf.shtml'
  },
  // {
  //   img:'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/news/new94.jpeg',
  //   type:'抚州市人民政府 ',
  //   time:'2020-10-27',
  //   title:'肖毅会见阿里巴巴商学院负责人',
  //   desc:'10月26日下午，市委书记肖毅在市行政中心会见了来我市参加中国·抚州“才子之乡”首届人才大会的杭州师范大学阿里巴巴商学院创始人、党委书记张佐和趣探路（杭州）网络技术有限公司董事长、浙江省抚州商会常务副会长、抚州市驻浙江招才引智工作站副站长熊伟一行',
  //   id:'94',
  //   newsType:'1',
  //   detail:'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/news/detail94.png',
  //   linkUrl: 'https://mp.weixin.qq.com/s?__biz=MzU3MDkzMjE5MA==&mid=2247508390&idx=1&sn=7788ddd740eec8388ee2d0c1ab584f7f&chksm=fce50f17cb9286012fd737f667041ab3aa8ce61e0b200848f6330cb7243f3460b493eb8d7427&mpshare=1&scene=1&srcid=1027Hp2vm63TIsy0hOB0KE82&sharer_sharetime=1612347958077&sharer_shareid=3c8d356f96164902c45b8a50ef4a4d4b&key=00639cb15c3f65e34c19c3b2b1dd16edf6d94af6dd42570346e0aeffedf4df43354c41d995c95cf9b5957cbcdf7b2c34484cde78d0dd4f5182ed3d111a95fd1c712dbf61f379f54321a55583f4e6a0e3000bac16680e3223881e5dc03af8752de8bbbd35c9bcae99bbdfc01ec5d7fd4eba716d464a505b1b9d249a98e395c9e1&ascene=0&uin=MTUwMTY0MzYwMQ%3D%3D&devicetype=iMac+MacBookPro12%2C1+OSX+OSX+10.15.7+build(19H2)&version=12010110&nettype=WIFI&lang=zh_CN&fontScale=100&exportkey=AZuLl01AU5LzkuzAXjjEKYE%3D&pass_ticket=K3p6%2BAjPIijUURZSBl5%2BYkjPvV%2FdctinKKNvwdPfQBJhYhEczrGnp4RnvfpjWB6Y&wx_header=0'
  // },

  {
    img: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/news/new93.png',
    type: ' 抚州微先锋 ',
    time: ' 2020-10-17',
    title: '重磅！抚州市驻浙江招才引智工作站揭牌运行',
    desc: '10月15日下午，抚州市驻浙江招才引智工作站揭牌仪式在杭州隆重举行。挪威科学院院士白勇，中国科学院院士朱位秋，中国工程院院士龚晓南，中国科学院院士杨树锋，中国科学院院士叶志镇，加拿大国家工程院院士朱志伟，浙江省纪委原常务副书记杨晓光，浙江省人大财经委原主任丁耀民',
    id: '93',
    newsType: '1',
    detail: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/news/detail93.png',
    linkUrl: 'https://mp.weixin.qq.com/s?__biz=MjM5MzQ1MzM3Ng==&mid=2651296188&idx=1&sn=f88efed9caac0eedff759f6c15b7c2b7&chksm=bd65f5938a127c850ee85142d3014754d472d685ef8b5f93e9ec904c90d674146b1c91c5ab17&mpshare=1&scene=1&srcid=10174NwuizlieokcZ1d2N1hw&sharer_sharetime=1612348253530&sharer_shareid=3c8d356f96164902c45b8a50ef4a4d4b&key=c8f6af60be7f3c317b64a34c636113f712ce0866220d009e4a9dfdc7e9a4a46bf8ea71219b15ec72b0346814c3eefa223be7dd67307249c4bd7e10bcc78ae95c22d16bf633f6eff93d1d3c4f8a32d530da3fb2fb1b4337c00ffb54f568d9a7be593794835ac44d8447c17d70b13e59797c701fcbd3f6f25e1049f92d35c7d87e&ascene=0&uin=MTUwMTY0MzYwMQ%3D%3D&devicetype=iMac+MacBookPro12%2C1+OSX+OSX+10.15.7+build(19H2)&version=12010110&nettype=WIFI&lang=zh_CN&fontScale=100&exportkey=Aa1dc4zCBHgxBnE%2FVrV7qAM%3D&pass_ticket=K3p6%2BAjPIijUURZSBl5%2BYkjPvV%2FdctinKKNvwdPfQBJhYhEczrGnp4RnvfpjWB6Y&wx_header=0'
  },

  {
    img: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/news/new99.png',
    type: ' 趣探路',
    time: '2020-09-02 17:14:30',
    title: '趣探路成为钉钉首批知识服务商，积极推动教育信息化',
    desc: '8月27日，在教育部召开的2020年金秋系列发布会第一场发布会上，教育部基础教育司吕玉刚司长指出，线上教育确实在疫情期间有效地支撑了“停课不停学”工作，发挥了重要的作用。教育部将把做好线上教学工作作为推进“互联网+教育”的战略工程来看待，作为实现教育现代化的一个重要抓手，也就是以教育信息化来引领、带动教育现代化。',
    id: '99',
    newsType: '2',
    detail: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/news/detail99.png',
    linkUrl: 'https://mp.weixin.qq.com/s?__biz=MjM5ODUyOTE2NQ==&mid=2247498469&idx=1&sn=2ecdc6ae814c82f43c6dba1b5e4a1d86&chksm=a6cbeb0791bc6211473bd8c262f0f51c80b4bc0521834d5be6e6c8a5a000a2230a8a8c6b6976&mpshare=1&scene=1&srcid=1228KXyvtffXBhabLvO2i5F0&sharer_sharetime=1609295135035&sharer_shareid=3c8d356f96164902c45b8a50ef4a4d4b&key=3183ee012c4b84b253ad932dd98920551dbdb102bb765a9ca3e9172efcd75571da7a8e92874fc2f283f24ece33d3d99036fc6db7ec8b8f136aa2262f9771e26d6680f930e0acf74807a705c5e58196333c510a923da1e8ac0c68924e97f8e31250966c03464d35dae359b2a553ce9c36bcbbf5590c07bf2312ad5c8d522bec65&ascene=0&uin=MTUwMTY0MzYwMQ%3D%3D&devicetype=iMac+MacBookPro12%2C1+OSX+OSX+10.15.7+build(19H2)&version=12010110&nettype=WIFI&lang=zh_CN&fontScale=100&exportkey=AQz0ToSW1pyxdAX88H3JaoQ%3D&pass_ticket=s9T30RJf4mitRFeCMnb1hFLdumytaeSz2u0KSg8vvzhQCHUVNEB4M4%2FU2nlpWWqq&wx_header=0'
  },
  {
    img: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/news/new3.png',
    type: '中国财富网官方',
    time: '2020-07-02 20:03',
    title: '浙江大学国际联合商学院与趣探路签署战略合作协议',
    desc: '七月一日，在美丽的浙江大学海宁校区，浙江大学国际联合商学院（简称浙大ZIBS）与趣探路-留学教育平台签署战略合作协议，双方本着携手合作、共同创新的原则，将在海外优质教育资源对接、海外择校与择业公益培训、海外品牌打造、数字化信息平台构建等方面开展紧密有深度的合作，共同打造围绕优质海外教育和工作实习等资源信息共享的网络场所和在线校园教育资源的互动生态平台。',
    id: '1',
    newsType: '1',
    detail: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/news/detail1.png',
    linkUrl: 'https://baijiahao.baidu.com/s?id=1671106586828607908&wfr=spider&for=pc'
  },
  {
    img: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/news/new1.png',
    type: "新华社",
    time: '2020-06-01 15:43',
    title: '留学社区“趣探路”牵手支付宝，这是什么操作？',
    desc: '中国财富网讯 （宣希）出国留学社区平台“趣探路”与支付宝牵手，受到潜在留学生家庭的关注。相关数据显示，2014年中国成为世界第一大留学生来源国，2017年出国留学人数首次突破60万大关。中国留学生在留学目的地选择上更加多元。与此同时，“低龄化”出国留学趋势持续增长。随着我国人均收入不断提高，家庭教育投资相应增加，出国留学群体更加大众化。',
    id: '2',
    newsType: '1',
    detail: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/news/detail2.png',
    linkUrl: 'https://xhpfmapi.zhongguowangshi.com/vh512/share/9139246?channel=weixin'
  },
  {
    img: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/news/new4.png',
    type: '趣探路',
    time: '2020-04-15',
    title: '民建省委会副主委、杭州市委会主委郭清晔，省委统战部部务会议成员、干部处处长王高瞻等赴趣探路开展三服务调研',
    desc: '4月15日上午，民建省委会副主委、杭州市委会主委郭清晔，省委统战部部务会议成员、干部处处长王高瞻，干部处副处长徐海江，党派处副处长陈豹，以及民建杭州市委会宣传处副处长蒋歆歆一行来到位于未来科技城海创园的趣探路（杭州）网络技术有限公司进行考察调研工作。座谈会上，趣探路CEO熊伟详细汇报了企业的总体情况，目前的发展和疫情期间的主要工作，包括人才引进和培养方面的具体做法',
    id: '3',
    newsType: '2',
    detail: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/news/detail3.png',
    linkUrl: 'https://mp.weixin.qq.com/s/wMHBrHb6UIVQeUpSqPp8ag'
  },
  {
    img: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/news/new1.png',
    type: '中国财富网官方',
    time: '2019-05-27 14:49',
    title: '官宣！趣探路携手支付宝校园生活平台 共创数字留学新社区！',
    desc: '重要消息！趣探路作为支付宝合作服务商已经与支付宝建立初步合作啦！那么问题来了趣探路和支付宝的合作到底有哪些呢？一起来看看吧！合作一：内容合作趣探路－留学教育社区是一个聚焦海外教育的出国留学社区平台，社区内容包括海外留学、游学、微留学、国际教育等，涵盖出国前、出国中、出国后等全时间线。',
    id: '5',
    newsType: '1',
    detail: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/news/detail5.png',
    linkUrl: 'https://baijiahao.baidu.com/s?id=1668006533475148217&wfr=spider&for=pc'
  },
  {
    img: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/news/new2.png',
    type: '环球网',
    time: '2020-01-02 14:59',
    title: '全力打造新兴留学平台 用互联网方式重构展会',
    desc: '和几个在留学机构工作的朋友聊天,问他们现在还去参加一些线下的留学展会吗?“以前经常参加,现在去的少了,展会上同行的人数比客户还多呢。”“参展费随随便便就2、3万,最小的展位费用也不低,3*3的面积,一个LOGO展示,最多再竖个易拉宝,就没有别的了,这钱花得不值。”“展会办个一两天,路过的也就几十人,尤其是我们这些没名气的小机构,无人问津,啥都捞不着。”',
    id: '4',
    newsType: '1',
    detail: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/news/detail4.png',
    linkUrl: 'https://baijiahao.baidu.com/s?id=1654598800239361274&wfr=spider&for=pc'
  }
]