<template>
  <div class="navBar">
    <ul class="navMain">
      <router-link class="navItem" to="/" tag="li"><img class="logo" src="https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/logo.png" alt=""></router-link>
      <router-link class="navItem" to="/" tag="li">首页</router-link>
      <router-link class="navItem" to="solution" tag="li">解决方案 <i class="iconfont icon-click top"></i>
        <ul class="location">
          <router-link to="solution" class="locitem">海外学习提升 <i class="iconfont icon-enter enter" /></router-link>
          <router-link to="solucloud" class="locitem">校外教育云平台 <i class="iconfont icon-enter enter" /></router-link>
          <router-link to="soluvenue" class="locitem">数智场馆 <i class="iconfont icon-enter enter" /></router-link>
        </ul>
      </router-link>
      <!-- <router-link class="navItem" to="studyabroad" tag="li">留学问答</router-link> -->
      <li class="navItem" @click="handleClick">留学问答</li>
      <router-link class="navItem" to="merchant" tag="li">商家中心</router-link>
      <router-link class="navItem" to="framer" tag="li">创作者计划</router-link>
      <router-link class="navItem" to="about" tag="li">关于我们</router-link>
    </ul>
  </div>
</template>
<script>
export default {
  methods: {
    handleClick() {
      window.open('https://ask.qutanlu.com/', '_blank')
    }
  }
}
</script>
<style scoped lang="scss">
.navBar{
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  position: absolute;
  top: 0;
  z-index: 9;
  .navMain{
    width: 1220px;
    height: 80px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .navItem{
      height: 100%;
      font-size: 16px;
      color: #444444;
      margin-right: 80px;
      display: flex;
      align-items: center;
      position: relative;
      .logo{
        width: 82px;
        height: 40px;
      }
      .top{
        font-size: 8px;
        margin-left: 5px;
        transform: rotate(90deg);
      }
      .location{
        position: absolute;
        width: 185px;
        background: #fff;
        top: 80px;
        left: -20px;
        font-size: 16px;
        font-weight: bold;
        color: #121212;
        text-align: left;
        padding: 20px 20px 40px;
        display: none;
        .locitem{
          margin-top: 20px;
          display: flex;
          align-items: center;
          .enter{
            font-size: 13px;
            margin-left: 8px;
          }
          &:hover{
            color: #FAE204;
          }
        }
      }
      &:hover{
        .location{
          display: block;
        }
        .top{
          font-size: 8px;
          margin-left: 5px;
          transform: rotate(270deg);
        }
      }
    }
    .router-link-exact-active {
      font-size: 16px;
      font-weight: bold;
      color: #121212;
    }
  }
}
</style>
