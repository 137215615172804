<template>
  <div class="home">
    <div class="banner">
      <div class="banxin">
        <img class="title hidden-xs-only" src="https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/home/homelogo.png" />
        <img class="title-sm hidden-sm-and-up" src="https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/home/homelogo.png" />
      </div>
      <!-- <img src="https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/home/indexbanner.png" alt=""> -->
      <div class="movebix">
        <img src="https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/home/movebase.png" alt="" class="movebase">
        <img src="https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/home/move-notebook.png" alt="" class="move-notebook">
        <img src="https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/home/move-card.png" alt="" class="move-card">
        <img src="https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/home/move-letter.png" alt="" class="move-letter">
      </div>
    </div>
    <div class="stepone">
      <div class="stepone_l">
        <div class="title">
          <img class="icon" src="https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/home/cloudicon.png" alt="">
          <span class="txt">科技向善，教育公平</span>
        </div>
        <div class="desc">数字教育云平台，将最好的校外教育给中国的青少年儿童</div>
        <div class="todetail" @click="handleClick('solucloud')">详细方案 <i class="iconfont icon-enter enter" /></div>
      </div>
      <div class="stepone_r">
        <img class="img" src="https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/home/cloudimg.png" alt="">
      </div>
    </div>
    <div class="steptwo">
      <div class="stepone_l">
        <div class="title">
          <img class="icon" src="https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/home/zaizhiicon.png" alt="">
          <span class="txt">起点更高，成功更近</span>
        </div>
        <div class="desc">不出国 免联考 申读海外名校硕/博项目</div>
        <div class="menus">
          <div class="todetail sao">扫一扫
            <div class="codebox">
              <div class="codeitem">
                <div class="imgborder">
                  <img class="img" src="https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/home/zaizhi-alipay.png" alt="">
                </div>
                <p class="tip"><span class="alipay">支付宝</span> 扫一扫去学习</p>
              </div>
              <div class="codeitem">
                <div class="imgborder">
                  <img class="img" src="https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/home/zaizhi-weapp.png" alt="">
                </div>
                <p class="tip"><span class="wechat">微信</span> 扫一扫去学习</p>
              </div>
            </div>
          </div>
          <div class="todetail" @click="handleClick('solution')">详细方案 <i class="iconfont icon-enter enter" /></div>
        </div>
      </div>
      <div class="stepone_r">
        <el-carousel :interval="5000" arrow="always" :loop="false">
          <el-carousel-item v-for="item in twodata" :key="item">
            <img :src="item" class="img" alt="">
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="stepthree">
      <div class="stepone_l">
        <div class="title">
          <img class="icon" src="https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/home/sqicon.png" alt="">
          <span class="txt">视野所至，幸福所及</span>
        </div>
        <div class="desc">分享真实的留学攻略和生活</div>
        <img  class="searchicon" src="https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/home/sqsearch.png" alt="">
        <div class="todetail">扫一扫
          <div class="codebox">
            <div class="codeitem">
              <div class="imgborder">
                <img class="img" src="https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/home/shequ-alipay.png" alt="">
              </div>
              <p class="tip"><span class="alipay">支付宝</span> 扫一扫去留学</p>
            </div>
          </div>
        </div>
      </div>
      <div class="stepone_r">
        <el-carousel :interval="5000" arrow="always" :loop="false">
          <el-carousel-item v-for="item in sqdata" :key="item">
            <img :src="item" class="img" alt="">
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="stepfour">
      <div class="title">
        <p class="title_l">新闻中心</p>
        <router-link to="news" class="title_r">更多新闻<i class="iconfont icon-enter enter" /></router-link>
      </div>
      <div class="news">
        <div class="box" v-for="(item,index) in newsdata" :key="index" @click="handleNews(item)">
          <div class="item"  v-if="index < 3">
            <img class="img" :src="item.img" alt="">
            <div class="info">
              <span class="type">{{item.type}}</span>
              <span class="time">{{item.time}}</span>
            </div>
            <div class="newtitle textEllipsis1">{{item.title}}</div>
            <div class="desc textEllipsis2">{{item.desc}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {newslist} from '../utils/data'
export default {
  name: 'Home',
  data () {
    return {
      twodata:[
        'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/home/zaizhibanner1.png',
        'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/home/zaizhibanner2.png'
      ],
      sqdata:[
        'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/home/sqbanner1.png',
        'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/home/sqbanner2.png',
        'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/home/sqbanner3.png',
        'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/home/sqbanner4.png'
      ],
      newsdata:[]
    }
  },
  mounted () {
    newslist.map((item,index)=>{
      if (index < 3) {
        this.newsdata.push(item)
      }
    })
    
  },
  methods: {
    handleClick(path) {
      this.$router.push(path)
    },
    handleNews(item) {
      this.$router.push({
        path: 'newsdetail',
        query: {
          id: item.id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.banner{
  height: 663px;
  width: 100%;
  min-width: 1250px;
  margin-bottom: 110px;
  position: relative;
  z-index: 1;
  background-color: rgba(253, 253, 246, 1);
  overflow: hidden;
  .banxin {
    width: 1220px;
    margin: 200px auto 0;
    img {
      width: 427px;
      height: 273px;
    }
    .title-sm {
      margin: 0 auto;
    }
  }
  .movebix {
    position: absolute;
    width: 1000px;
    height: 545px;
    right: -60px;
    bottom: 20px;
  }
  .movebase {
    width: 100%;
  }
  .move-notebook {
    position: absolute;
    width: 60px;
    height: 83px;
    top: 160px;
    left: 240px;
    animation: notebook 3s infinite;
  }
  .move-card {
    position: absolute;
    width: 93px;
    height: 141px;
    top: 36px;
    right: 250px;
    animation: card 4s infinite;
  }
  .move-letter {
    position: absolute;
    width: 38px;
    height: 54px;
    bottom: 180px;
    right: 350px;
    animation: letter 2.5s infinite;
  }
}
@keyframes notebook {
  0% {
    top: 160px;
  }
  50% {
    top: 140px;
  }
  100% {
    top: 160px;
  }
}
@keyframes card {
  0% {
    top: 36px;
  }
  50% {
    top: 16px;
  }
  100% {
    top: 36px;
  }
}
@keyframes letter {
  0% {
    right: 350px;
  }
  50% {
    right: 360px;
  }
  100% {
    right: 350px;
  }
}
.stepone,.steptwo,.stepthree{
  width: 1220px;
  margin: 0 auto 160px;
  display: flex;
  justify-content: space-between;
  .stepone_l{
    flex: 1;
    position: relative;
    .title{
      display: flex;
      align-items: center;
      .icon{
        width: 31px;
        height: 34px;
        margin-right: 12px;
      }
      .txt{
        font-size: 39px;
        font-weight: bold;
        color: #121212;
      }
    }
    .desc{
      font-size: 20px;
      color: #121212;
      margin-top: 40px;
    }
    .todetail{
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 185px;
      height: 62px;
      font-size: 16px;
      font-weight: 500;
      color: #FFFFFF;
      background: #444444;
      border-radius: 4px;
      cursor: pointer;
      .enter{
        font-size: 13px;
        margin-left: 16px;
        color: #fff;
      }
    }
  }
  .stepone_r{
    width: 714px;
    height: 463px;
    margin: 0 0 0 110px;
    .img{
      width: 100%;
      height: 100%;
    }
  }
}
.stepone{
  .stepone_r{
    position: relative;
    &::after{
      content: '';
      position: absolute;
      top: -40px;
      left: -40px;
      bottom: -40px;
      right: -40px;
      background: url('https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/home/cloudbg.png');
      background-size: cover;
      z-index:-1;
    }
  }
}
.steptwo{
  flex-direction:row-reverse;
  .stepone_l{
    .menus{
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .todetail{
        position: static;
        width: 185px;
        height: 62px;
        font-size: 16px;
        font-weight: 500;
        color: #FFFFFF;
        background: #444444;
        border-radius: 4px;
        cursor: pointer;
        .enter{
          font-size: 13px;
          margin-left: 16px;
          color: #fff;
        }
      }
      .sao{
        width: 168px;
        height: 62px;
        background: #FAE204;
        border-radius: 4px;
        color: #121212;
        margin-right: 40px;
        position: relative;
        .codebox{
          position: absolute;
          left: 0;
          top: -178px;
          width: 274px;
          height: 158px;
          background: #FFFFFF;
          box-shadow: 0px 1px 6px 0px rgba(68, 68, 68, 0.1);
          display: flex;
          padding: 10px 0;
          display: none;
          .codeitem{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: center;
            .imgborder{
              width:102px;
              height: 102px;
              .img{
                width: 100%;
                height: 100%;
              }
            }
            .tip{
              margin-top: 4px;
              font-size: 13px;
              font-weight: 600;
              color: #000;
              width: 100%;
              text-align: center;
              .alipay{
                color: #0876F7;
              }
              .wechat{
                color: #1AC652;
              }
            }
          }
          &::after{
            position: absolute;
            bottom: -18px;
            left: 20px;
            content: '';
            width: 18px;
            height: 18px;
            transform: rotate(45deg);
            bottom: -9px;
            z-index: -1;
            box-shadow: 0px 1px 6px 0px rgba(68, 68, 68, 0.1);
            // position: absolute;
            // bottom: -18px;
            // left: 20px;
            // content: '';
            // width: 0;
            // height: 0;
            // border-style: solid;
            // border-width: 31.2px 18px 0 18px;
            // border-color: #fff transparent transparent transparent;
            // // box-shadow: 0px 1px 6px 0px rgba(68, 68, 68, 0.1);
          }
        }
        &:hover{
          .codebox{
            display: flex;
          }
        }
      }
    }
  }
  .stepone_r{
    margin: 0 80px 0 0;
    position: relative;
    &::after{
      content: '';
      position: absolute;
      top: -40px;
      left: -40px;
      bottom: -40px;
      right: -40px;
      background: url('https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/home/zaizhibg.png');
      background-size: cover;
      z-index:-1;
    }
  }
}
.stepthree{
  .stepone_l{
    .searchicon{
      width: 100%;
      height: 60px;
      margin-top: 19px;
    }
    .todetail{
      width: 168px;
      background: #FAE204;
      font-size: 16px;
      font-weight: 500;
      color: #121212;
      .codebox{
        position: absolute;
        left: 0;
        top: -178px;
        width: 137px;
        height: 158px;
        background: #FFFFFF;
        box-shadow: 0px 1px 6px 0px rgba(68, 68, 68, 0.1);
        display: flex;
        padding: 10px 0;
        display: none;
        .codeitem{
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          .imgborder{
            width:102px;
            height: 102px;
            .img{
              width: 100%;
              height: 100%;
            }
          }
          .tip{
            margin-top: 4px;
            font-size: 13px;
            font-weight: 600;
            color: #000;
            width: 100%;
            text-align: center;
            .alipay{
              color: #0876F7;
            }
            .wechat{
              color: #1AC652;
            }
          }
        }
        &::after{
          position: absolute;
          bottom: -18px;
          left: 20px;
          content: '';
          width: 18px;
          height: 18px;
          transform: rotate(45deg);
          bottom: -9px;
          z-index: -1;
          box-shadow: 0px 1px 6px 0px rgba(68, 68, 68, 0.1);
          // position: absolute;
          // bottom: -18px;
          // left: 20px;
          // content: '';
          // width: 0;
          // height: 0;
          // border-style: solid;
          // border-width: 31.2px 18px 0 18px;
          // border-color: #fff transparent transparent transparent;
        }
      }
      &:hover{
        .codebox{
          display: flex;
        }
      }
    }
  }
  .stepone_r{
    margin: 0 0 0 70px;
    position: relative;
    &::after{
      content: '';
      position: absolute;
      top: -40px;
      left: -40px;
      bottom: -40px;
      right: -40px;
      background: url('https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/home/sqbg.png');
      background-size: cover;
      z-index:-1;
    }
  }
}
.stepfour{
  width: 1220px;
  margin: 0 auto 160px;
  .title{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
    .title_l{
      font-size: 44px;
      font-weight: 600;
      color: #121212;
    }
    .title_r{
      width: 185px;
      height: 62px;
      background: #444444;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      color: #FFFFFF;
      cursor: pointer;
      .enter{
        font-size: 13px;
        margin-left: 16px;
        color: #fff;
      }
    }
  }
  .news{
    display: flex;
    justify-content: space-between;
    .box{
      display: flex;
      justify-content: space-between;
    }
    .item{
      cursor: pointer;
      width: 375px;
      .img{
        width: 100%;
        height: 250px;
      }
      .info{
        margin-top: 40px;
        font-size: 14px;
        color: #666666;
        .type{
          padding-right: 20px;
        }
        .time{
          padding-left: 20px;
        }
      }
      .newtitle{
        margin-top: 16px;
        font-weight: 600;
        color: #000000;
        font-size: 18px;
      }
      .desc{
        margin-top: 10px;
        color: #000000;
        font-size: 14px;
      }
    }
  }
}
</style>
<style lang="scss" >
.el-carousel{
  height: 100%;
  .el-carousel__container{
    height: 100%;
    .el-carousel__item{
      .img{
        width: 100%;
        height: 100%;
      }
    }
  }
}
.el-carousel__arrow{
  width: 48px !important;
  height: 48px !important;
  background-color: rgba($color: #e8e8e8, $alpha: 0.4) !important;
  .el-icon-arrow-right,.el-icon-arrow-left{
    font-size: 28px !important;
  }
}
</style>

