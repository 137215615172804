<template>
  <div class="footer">
    <div class="footercon">
      <div class="con_l">
        <span>邮箱：qutanlu@qutanlu.com </span>
        <span
          >地址：浙江省杭州市余杭区五常街道爱橙街1号10 幢6单元 1层 W107 室</span
        >
      </div>
      <div class="con_r">
        <a href="https://beian.miit.gov.cn" target="blank" class="aFont"
          >侵权投诉指引浙ICP备 20007955号 Copyright © 2019-2024
          趣探路（杭州）网络技术有限公司</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.footer {
  height: 130px;
  background: #f6f6f6;
  .footercon {
    height: 100%;
    width: 1220px;
    font-size: 14px;
    font-weight: 500;
    color: #8c8889;
    padding: 30px 0;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .con_l {
      display: flex;
      flex-flow: column;
      span {
        margin-top: 10px;
      }
    }
    .con_r {
      display: flex;
      align-items: flex-end;
      padding-bottom: 10px;
      .aFont {
        color: #8c8889;
        cursor: pointer;
      }
    }
  }
}
</style>
