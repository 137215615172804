<template>
  <div id="app">
    <div id="mainnav" :style="{backgroundColor: 'rgba(255, 255, 255, ' + RGBA + ')'}">
      <NavBar />
    </div>
    <router-view/>
    <div id="footer">
      <Footer />
    </div>
  </div>
</template>
<script>
import NavBar from '@/components/navBar.vue'
import Footer from '@/components/footer.vue'
export default {
  components: {
    NavBar,
    Footer
  },
  data() {
    return {
      RGBA: 0
    }
  },
  mounted() {    
    window.addEventListener('scroll', this.handleScroll, true)  
  },
  methods: {
    handleScroll(e) {
      let scrollTop = e.target.documentElement.scrollTop || e.target.body.scrollTop
      if (scrollTop > 150) {
        this.RGBA = 1
      } else {
        this.RGBA = scrollTop / 150
      }
    }
  }
}
</script>
<style lang="scss">
#mainnav {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 80px;
  z-index: 9;
}
</style>
